import NextLink from 'next/link';
import PropTypes from 'prop-types';

import Button from 'shopper/components/Button';
import Heading from 'shopper/components/Heading';
import Text from 'shopper/components/Text';

import Image from 'components/Image';

import { toCouponStoreWithCouponIdLink } from 'lib/links';
import placeholder from 'lib/placeholder';

const LatestCouponsItem = ({
  couponDiscountOn = null,
  couponDiscountValue,
  couponId,
  storeImage,
  storeName,
  storeSlug,
}) => (
  <div className="flex w-full items-start border-b border-solid border-neutral-high-200 py-4 dark:border-neutral-low-300">
    <Image
      alt={`Image da loja ${storeName}`}
      className="mr-4 rounded-2 border border-solid border-neutral-high-400"
      height={64}
      src={storeImage}
      width={64}
    />
    <div>
      <Text className="mb-1" size="size2">
        Cupom {storeName}
      </Text>
      <Heading
        as="span"
        className="text-primary-500 dark:text-primary-200"
        size="size6"
      >
        {couponDiscountValue}
      </Heading>
      {couponDiscountOn && <Text size="size2">em {couponDiscountOn}</Text>}
      <NextLink
        href={toCouponStoreWithCouponIdLink(couponId, storeSlug)}
        passHref
      >
        <Button
          as="a"
          className="mb-1 mt-2 px-2"
          size="size4"
          type="primary-stroke"
        >
          {placeholder('actions.pickCoupon')}
        </Button>
      </NextLink>
    </div>
  </div>
);

LatestCouponsItem.propTypes = {
  couponDiscountOn: PropTypes.string,
  couponDiscountValue: PropTypes.string.isRequired,
  couponId: PropTypes.number.isRequired,
  storeSlug: PropTypes.string.isRequired,
  storeImage: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
};

export default LatestCouponsItem;
