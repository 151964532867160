import PropTypes from 'prop-types';
import { cloneElement } from 'react';

import Icon from '../Icon';
import Spinner from '../Spinner';

const IconRight = ({ error, iconRight, loading, success, onClick }) => {
  if (loading) {
    return <Spinner size="size2" />;
  }

  if (error) {
    return (
      <Icon
        className="text-error-300 dark:text-error-200"
        name="error-circle"
        size="size2"
      />
    );
  }

  if (success) {
    return (
      <Icon
        className="text-success-300 dark:text-success-300"
        name="check-circle"
        size="size2"
      />
    );
  }

  return cloneElement(iconRight, {
    size: 'size2',
    onClick,
  });
};

IconRight.propTypes = {
  error: PropTypes.bool,
  iconRight: PropTypes.shape(),
  loading: PropTypes.bool,
  success: PropTypes.bool,
  onClick: PropTypes.func,
};

export default IconRight;
