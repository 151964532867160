import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';

import AutoComplete from 'shopper/components/AutoComplete';
import Icon from 'shopper/components/Icon';
import Input from 'shopper/components/Input';

import placeholder from 'lib/placeholder';
import { storeQueryKeys } from 'lib/queryKeys';
import { debounce } from 'lib/utils';

import { getStoreAutocompleteKeywords } from 'services/store';

const AUTOCOMPLETE_INITIALIZATION_LENGTH = 2;

const StoreWithCouponsSearch = ({ className }) => {
  const router = useRouter();
  const [inputValue, setInputValue] = useState('');

  const {
    data: itemsToAutoComplete,
    refetch,
    isInitialLoading,
  } = useQuery({
    queryKey: storeQueryKeys.autoCompleteList({
      query: inputValue.slice(0, 3),
    }),
    queryFn: ({ signal }) =>
      getStoreAutocompleteKeywords(
        { query: inputValue.slice(0, 3) },
        { signal }
      ),
    enabled: false,
    cacheTime: 60 * 60 * 60, // 1min
    select: (data) =>
      data.map(({ storeSlug, storeTitle, ...rest }) => ({
        /**
         * TODO:
         * - remove this replace after backend `store_slug` field fix
         */
        value: storeSlug.replace(/_/g, '-'),
        text: storeTitle,
        ...rest,
      })),
  });

  const debouncedRefetch = useCallback(
    debounce(() => {
      refetch();
    }, 300),
    []
  );

  const onAutoCompleteChange = ({ target: { value } }) => {
    setInputValue(value.toLowerCase());

    if (value.length > AUTOCOMPLETE_INITIALIZATION_LENGTH) {
      debouncedRefetch();
    }
  };

  const onCleanup = () => {
    setInputValue('');
  };

  const gotoStorePage = (storeSlug) => router.push(`/cupons/loja/${storeSlug}`);

  return (
    <AutoComplete.Root
      className={className}
      data-test-selector="store-with-coupons-autocomplete"
      full
    >
      <AutoComplete.Input
        as={Input}
        iconRight={<Icon name="search" />}
        placeholder={placeholder('inputs.placeholders.searchStore')}
        value={inputValue}
        onChange={onAutoCompleteChange}
        onCleanup={onCleanup}
      />
      <AutoComplete.List items={itemsToAutoComplete} loading={isInitialLoading}>
        {(items) =>
          items.map(({ control, key, couponCount }) => (
            <AutoComplete.ListItem
              key={key}
              control={control}
              counter={couponCount}
              data-test-selector="cupons-autocomplete-item"
              onSelect={gotoStorePage}
            />
          ))
        }
      </AutoComplete.List>
    </AutoComplete.Root>
  );
};

export default StoreWithCouponsSearch;
